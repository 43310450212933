
const AccountRow = (props) => {
    return (
        <div className="row mb-2">
            <div className="col-auto col-sm-3 col-lg-2">{props.label}:</div>
            <div className="col">{props.children}</div>
        </div>
    );
}

export default AccountRow;
