import React from 'react';
import { Formik, Form } from 'formik';
import FormField from '../../components/FormField';
import Loader from '../../components/Loader';
import Button from 'react-bootstrap/Button';
import OrderHistory from './OrderHistory';
import Breadcrumb from '../../components/Breadcrumb';
import AccountRow from './AccountRow';
import Account2FA from './Account2FA';
import { PREF_CATEGORY_OPTS, FEATURE_2FA_NZ } from '../../constants';
import { isPossiblePhoneNumber } from 'libphonenumber-js';

import { connect } from 'react-redux';
import { updateProfile } from '../../redux/User';
const mapStateToProps = state => {
    return {
        userData: state.user.data.actingAsUser == null ? state.user.data : state.user.data.actingAsUser,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        updateProfile: (d,c,e) => { dispatch(updateProfile(d,c,e)); }
    }
}

class Account extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            serverError: false,
            editing2FA: false,
            loading2FA: false,
            step2FA: 1,
            error2FA: false,
            setupInfo: null,
            canResendEmail: true
        }
    }

    componentDidMount() {
        setTimeout(() => {window.scrollTo(0, 0);}, 100);
    }

    validateDetails = (values) => {
        let errors = {};
        if (!values.email || values.email === "") {
            errors.email = "Please enter your email";
        }
        if (!values.accountName || values.accountName === "") {
            errors.accountName = "Please enter your Account Name";
        }
        if (!values.firstName || values.firstName === "") {
            errors.firstName = "Please enter your First Name";
        }
        if (!values.lastName || values.lastName === "") {
            errors.lastName = "Please enter your Last Name";
        }
        if (values.password && values.password !== "" && (!values.confirmPassword || values.password !== values.confirmPassword)) {
            errors.confirmPassword = "Password must match";
        }
        if (values.phone && values.phone !== "") {
            const {userData} = this.props;
            let c = userData.country && userData.country.toLowerCase() === "nz" ? "NZ" : "AU";
            let p = isPossiblePhoneNumber(values.phone, c);
            if (!p) {
                errors.phone = "Please enter a valid phone number";
            }
        }
        return errors;
    }

    submitDetails = (values, formikBag) => {
        this.props.updateProfile(
            values,
            () => {
                formikBag.setSubmitting(false);
                this.setState({editing: false});
            },
            (error) => {
                let msg = "An error has occured";
                if (error.body && error.body.message) {
                    msg = error.body.message;
                }
                if (error.body && error.body.modelState) {
                    formikBag.setErrors(error.body.modelState);
                } else if (error.status === 409) {
                    formikBag.setErrors({email: "The email address is already in use"});
                }
                formikBag.setSubmitting(false);
                this.setState({serverError: msg});
            }
        )
    }
    
    render() {
        const {userData} = this.props;
        return (
          <div className="page mb-5 account container px-3 px-md-5">
                <Breadcrumb>Account</Breadcrumb>
                <h1 className="mb-5">Account</h1>
                <h3 className="mb-3">Details</h3>

                {this.state.editing &&
                    <Formik
                        validate={this.validateDetails}
                        onSubmit={this.submitDetails}
                        initialValues={{
                            accountName: userData.accountName,
                            companyName: userData.companyName,
                            firstName: userData.firstName,
                            lastName: userData.lastName,
                            email: userData.email,
                            phone: userData.phone,
                            primaryCategory: userData.primaryCategory
                        }}
                    >
                        {(formikBag) => (
                            <Form className="position-relative" style={{maxWidth: "590px"}}>
                                {formikBag.isSubmitting && <Loader />}
                                <FormField id="accountName" label="Account name" formikBag={formikBag} hasValidation />
                                <FormField id="companyName" label="Company name" formikBag={formikBag} />
                                <FormField id="firstName" label="First name" formikBag={formikBag} hasValidation />
                                <FormField id="lastName" label="Last name" formikBag={formikBag} hasValidation />
                                <FormField id="email" label="Email" formikBag={formikBag} hasValidation />
                                <FormField id="phone" label="Phone" formikBag={formikBag} />
                                <FormField id="primaryCategory" label="Primary category" type="select" options={PREF_CATEGORY_OPTS} formikBag={formikBag} />
                                <FormField id="password" label="New password" type="password" autoComplete="new-password" formikBag={formikBag} />
                                <FormField id="confirmPassword" label="Confirm new password" type="password" autoComplete="new-password" formikBag={formikBag} hasValidation />
                                {this.state.serverError && <p className="server-error text-danger py-3">{this.state.serverError}</p>}
                                <div className="text-right mt-5">
                                    <Button type="button" variant="outline-primary" onClick={() => { this.setState({editing: false, serverError: false}); }} className="mr-2">Cancel</Button>
                                    <Button type="submit" variant="primary">Save</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                }

                {!this.state.editing && <>
                    <AccountRow label="Account ID">{userData.accountId}</AccountRow>
                    <AccountRow label="Account name">{userData.accountName}</AccountRow>
                    <AccountRow label="Company name">{userData.companyName}</AccountRow>
                    <AccountRow label="First name">{userData.firstName}</AccountRow>
                    <AccountRow label="Last name">{userData.lastName}</AccountRow>
                    <AccountRow label="Email">{userData.email}</AccountRow>
                    <AccountRow label="Phone">{userData.phone}</AccountRow>
                    <AccountRow label="Country">{userData.country}</AccountRow>
                    <AccountRow label="Primary category">{userData.primaryCategory}</AccountRow>
                    <p className="mt-4"><Button variant="primary" onClick={() => { this.setState({ editing: true }); }}>Edit my details</Button></p>
                </>}
                
                {userData.country === "NZ" && FEATURE_2FA_NZ && <>
                  <h3 className="mb-3 mt-5">Two-Factor Authentication (2FA)</h3>
                  {!this.state.editing2FA &&
                    <AccountRow label="2FA method">{userData.twoFactorType} <Button type="button" variant="link" className="py-0" onClick={()=>{ this.setState({editing2FA: true}); }}>Change</Button></AccountRow>
                  }
                  {this.state.editing2FA && <Account2FA onSubmit={()=>{ this.setState({editing2FA: false}); }} onCancel={()=>{ this.setState({editing2FA: false}); }} />}
                </>}

                <hr className="my-5" />

                <OrderHistory thisUser={userData.actingAsUser ? userData.actingAsUser.accountId : userData.accountId} />

            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Account);
