import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import Modal from 'react-bootstrap/Modal';
import Loader from '../components/Loader';
import ReleaseListItem from '../components/ReleaseListItem';
import Download from '../components/Download';
import { PATH_RELEASES, PATH_OFFERS, PATH_NEWS, JUICERID_AU, JUICERID_NZ } from '../constants';
import { request } from '../functions/apiRequestWrapper';
import { DateTime } from 'luxon';
import sanitizeHtml from 'sanitize-html';

import { connect } from 'react-redux';
import { logout } from '../redux/User';
import { getReleases } from '../redux/Releases';
const mapStateToProps = state => {
    return {
        releases: state.releases.list,
        isLoadingReleases: state.releases.isLoading,
        country: state.user.data && state.user.data.country ? state.user.data.country : "AU"
    };
}
const mapDispatchToProps = dispatch => {
    return {
        getReleases: () => { dispatch(getReleases()); },
        logout: () => { dispatch(logout()); }
    }
}

class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            carousel: [],
            offers: [],
            isLoadingCarousel: false,
            isLoadingOffers: false,
            showDownloadModal: false
        }
    }

    componentDidMount() {
        this.getCarousel();
        this.getOffers();
        if (!this.props.releases || this.props.releases.length === 0) {
            this.props.getReleases();
        }
        setTimeout(() => {window.scrollTo(0, 0);}, 100);
    }

    getCarousel = () => {
        this.setState({isLoadingCarousel: true});
        request(
            `${process.env.REACT_APP_API}/1/cms/home`
        ).then((data) => {
            // console.log(data);
            this.setState({carousel: data, isLoadingCarousel: false});
        }).catch((error) => {
            if (error.status === 401) {
                this.props.logout();
            }
            console.error(error);
            this.setState({isLoadingCarousel: false});
        });
    }

    getOffers = () => {
        let today = DateTime.now().toISODate();
        request(
            `${process.env.REACT_APP_API}/1/cms/offers?$top=1&$skip=0&$orderby=closingDate asc&$filter=cast(closingDate, 'Edm.DateTimeOffset') ge ${today}`
        ).then((data) => {
            // console.log(data);
            this.setState({offers: data, isLoadingOffers: false});
        }).catch((error) => {
            if (error.status === 401) {
                this.props.logout();
            }
            console.error(error);
            this.setState({isLoadingOffers: false});
        });
    }

    render() {
        const {carousel, offers, isLoadingCarousel, isLoadingOffers} = this.state;
        const {releases, isLoadingReleases} = this.props;
        let juicerId = this.props.country === "NZ" ? JUICERID_NZ : JUICERID_AU;
        return (
            <div className="page home container px-3 px-md-5">

                <Helmet>
                    <script src='https://assets.juicer.io/embed.js' type='text/javascript'></script>
                    <link href='https://assets.juicer.io/embed.css' media='all' rel='stylesheet' type='text/css' />
                </Helmet>

                {carousel && carousel.length > 0 && <div className="mb-5">
                    <Carousel className="home-carousel" controls={false}>
                        {carousel.map((item, i) => {
                            let el = "div";
                            let elProps = {};
                            if (item.link) {
                                if (item.link.indexOf("http") === 0) {
                                    el = "a"
                                    elProps = {href: item.link, target: "_blank"}
                                } else {
                                    let link = item.link;
                                    if (item.link.indexOf("/") !== 0) {
                                        link = "/" + item.link;
                                    }
                                    el = Link;
                                    elProps = {to: link};
                                }
                            }
                            return (
                                <Carousel.Item as={el} key={`slide${i}`} {...elProps}>
                                    <div className="d-flex flex-column justify-content-between">
                                        <div className="text">
                                            {item.title && !item.subtitle && <h2 className="pb-3">{item.title}</h2>}
                                            {item.title && item.subtitle && <h3>{item.title}</h3>}
                                            {item.subtitle && <h5 className="pb-3">{item.subtitle}</h5>}
                                        </div>
                                        <div className="image flex-grow-1" style={{backgroundImage: `url("${item.image}")`}}>&nbsp;</div>
                                    </div>
                                </Carousel.Item>
                            );
                        })}
                    </Carousel>
                </div>}

                {releases && releases.length > 0 && <div className="list releases mb-5">
                    <h2 className="mb-4">Latest Releases</h2>
                    <div className="row">
                        {releases.slice(0, 4).map(release => {
                            return (
                                <div key={release.slug}  className="col-6 col-sm-3">
                                    <ReleaseListItem release={release} />
                                </div>
                            );
                        })}
                    </div>
                    <p>
                        <Button as={Link} to={PATH_RELEASES} className="mb-2 mr-2">View all releases</Button>
                        <Button variant="outline-primary" className="mb-2" onClick={() => { this.setState({showDownloadModal: true}); }}>Download releases data</Button>
                    </p>
                </div>}

                {offers && offers.length > 0 && <div className="row mb-5">
                    <div className="col-12">
                        <div className="row mb-5 offer-featured-item">
                            <div className="col-12 col-sm-6 col-md-8">
                                <div className="featured-img" style={{backgroundImage: `url("${offers[0].image}")`}} />
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <div className="py-2 py-sm-5 px-sm-2 px-md-3">
                                    <h2 className="mb-3">Latest Offer</h2>
                                    <h4><b>{offers[0].title}</b></h4>
                                    <p className="featured-subtitle">Available until {DateTime.fromISO(offers[0].closingDate).toLocaleString({day: 'numeric', month: 'long', year: 'numeric'})}</p>
                                    <div className="featured-desc mb-4">{sanitizeHtml(offers[0].description, {allowedTags: [], allowedAttributes: {}})}</div>
                                    <Button variant="primary" as={Link} to={`${PATH_OFFERS}/${offers[0].slug}`} className="mb-2 mr-2">Read more</Button>
                                    <Button variant="outline-primary" as={Link} to={PATH_OFFERS} className="mb-2 mr-2">View all offers</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

                {(isLoadingCarousel || isLoadingReleases || isLoadingOffers) && <Loader type="placeholder" />}

                <h2 className="mb-3">Latest News</h2>
                <ul className="juicer-feed hhq-news mb-3" data-feed-id={juicerId} data-columns="4" data-per="4" data-pages="1"></ul>
                <p className="mb-5"><Button as={Link} to={PATH_NEWS}>View all news</Button></p>

                <Modal show={this.state.showDownloadModal}>
                    <Modal.Header>
                        <Modal.Title>Download releases data</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Download />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={() => { this.setState({showDownloadModal: false}); }}>Exit</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
