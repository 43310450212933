
export const PATH_HOME = '/';
export const PATH_LOGIN = '/login';
export const PATH_REGISTER = '/signup';
export const PATH_FORGOT_PASS = '/forgot-password';
export const PATH_REQUEST_CONFIRMAION = '/request-email-confirmation';
export const PATH_CONFIRM_EMAIL = '/confirm';
export const PATH_RESET_PASS = '/reset';
export const PATH_RELEASES = '/releases';
export const PATH_CATALOGUE = '/catalogue';
export const PATH_OFFERS = '/offers';
export const PATH_NEWS = '/news';
export const PATH_ACCOUNT = '/account';
export const PATH_CART = '/cart';
export const PATH_SAVED_ORDERS = '/saved-orders';
export const PATH_SAVED_ORDER = '/saved-order';
export const PATH_CHECKOUT = '/checkout';
export const PATH_ORDER = '/order';
export const PATH_HELP = '/help';
export const PATH_CONTACT = '/contact';
export const PATH_ORDER_HISTORY = '/account/order';
export const PATH_ACT_AS_USER = '/act-as-user';
export const PATH_INVOICES = '/invoices';

export const pathRequiresAuth = (path) => {
  let a = [PATH_HOME, PATH_RELEASES, PATH_CATALOGUE, PATH_OFFERS, PATH_NEWS, PATH_ACCOUNT, PATH_CART, PATH_SAVED_ORDER, PATH_SAVED_ORDERS, PATH_ORDER, PATH_CHECKOUT, PATH_ORDER_HISTORY, PATH_ACT_AS_USER];
  let r = false;
  for (let i=0; i < a.length; i++) {
    if (path.indexOf(a[i]) === 0) {
      r = true;
      break;
    }
  }
  return r;
}

export const MONTH_OPTS = [
    {value: "01", label: "January"},
    {value: "02", label: "February"},
    {value: "03", label: "March"},
    {value: "04", label: "April"},
    {value: "05", label: "May"},
    {value: "06", label: "June"},
    {value: "07", label: "July"},
    {value: "08", label: "August"},
    {value: "09", label: "September"},
    {value: "10", label: "October"},
    {value: "11", label: "November"},
    {value: "12", label: "December"},
];

export const BICCODE_OPTS = [
    "",
    {value: "A", label: "THE ARTS"},
    {value: "B", label: "BIOGRAPHY & TRUE STORIES"},
    {value: "C", label: "LANGUAGE"},
    {value: "D", label: "LITERATURE & LITERARY STUDIES"},
    {value: "E", label: "ENGLISH LANGUAGE TEACHING (ELT)"},
    {value: "F", label: "FICTION & RELATED ITEMS"},
    {value: "G", label: "REFERENCE, INFORMATION & INTERDISCIPLINARY SUBJECT"},
    {value: "H", label: "HUMANITIES"},
    {value: "J", label: "SOCIETY & SOCIAL SCIENCES"},
    {value: "K", label: "ECONOMICS, FINANCE, BUSINESS & MANAGEMENT"},
    {value: "L", label: "LAW"},
    {value: "M", label: "MEDICINE"},
    {value: "P", label: "MATHEMATICS & SCIENCE"},
    {value: "R", label: "EARTH SCIENCES, GEOGRAPHY, ENVIRONMENT, PLANNING"},
    {value: "T", label: "TECHNOLOGY, ENGINEERING, AGRICULTURE"},
    {value: "U", label: "COMPUTING & INFORMATION TECHNOLOGY"},
    {value: "V", label: "HEALTH & PERSONAL DEVELOPMENT"},
    {value: "W", label: "LIFESTYLE, SPORT & LEISURE"},
    {value: "Y", label: "CHILDREN'S, YOUNG ADULT & EDUCATIONAL"}
];

export const STOCK_STATUS_OPTS = [
    "",
    "Not Yet Published",
    "In Stock",
    "Print On Demand",
    "Indent",
    "Out Of Stock",
    "Out Of Stock – Reprint Under Consideration",
    "Refer Customer Service"
];

export const FORMAT_OPTS = [
    "",
    "APB",
    "BOA",
    "BHB",
    "BKC",
    "BPB",
    "B+H",
    "B+P",
    "CD",
    "CHB",
    "NSP",
    "NSH",
    "NVL",
    "SLI",
    "TPB"
];

export const PREF_CATEGORY_OPTS = [
    "",
    "Book Industry News",
    "General Fiction",
    "Literary Fiction",
    "General NonFiction",
    "Lifestyle",
    "MindBodySpirit",
    "Reference",
    "Children's",
    "Religious",
    "SciFi Fantasy",
    "ABC Books"
];

export const JUICERID_AU = "harpercollins-books-australia";
export const JUICERID_NZ = "harpercollins-publishers-new-zealand";

export const DIGITAL_ASSETS_URL = "https://www.dropbox.com/sh/8uzkmn5385ys0g8/AADwzYXo_6pWHjVQT169rDcEa?dl=0";

export const FEATURE_INVOICES = true;
export const FEATURE_2FA_NZ = false;
